import React from 'react';
import './Bar.css';

export default class Bar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return (
      <div className="mobile-bar">
        <div className="container">
          <div id="music" className={`mobile-button ${this.props.page === 1 ? 'active' : ''}`} onClick={() => this.props.updatePage(1)}>
            <i className="fa fa-music" aria-hidden="true"></i>
          </div>
          <div id="photos" className={`mobile-button ${this.props.page === 2 ? 'active' : ''}`} onClick={() => this.props.updatePage(2)}>
            <i className="fa fa-camera-retro" aria-hidden="true"></i>
          </div>
          <div id="message" className={`mobile-button ${this.props.page === 3 ? 'active' : ''}`}onClick={() => this.props.updatePage(3)}>
            <i className="fa fa-commenting" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    );
  }
}