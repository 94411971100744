import React from 'react';
import './Message.css';

export default class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessageLoaded: false,
      message: '',
      messages: [
        "I think you're cute...like real cute :)",
        "Beep.Boop. Nelson wants you to know that he loves you",
        "You mean the 🌎 to me",
        "There's no one else I'd rather play split-screen with than you",
        "I've been so lucky to have you by my side for these last 3 years",
        "Wow...why are you always the first thing that I think about it??",
        "Abby...you're just perfect 🥰",
        "Ugh. You're distracting me from working...why do I think about you so much?",
        "I hope this makes you smile 😊",
        "How'd I get so lucky to have someone like you?",
        "ur cute, can I have a kiss?",
        "So heard you wanted Willie Nelson braids lol. 💆‍♀️",
        "you are literally the woman of my dreams",
        "Do you know I only like dancing with you??",
        "hey beautiful! I love you so much and I wouldn't know what to do without you."
      ]
    }
  }

  componentDidMount = () => {
    this.getNewMessage();
  }

  waitOnMessage = () => {
    setTimeout(() => {
      this.setState({
        isMessageLoaded: true
      })
    }, 3000)
  }

  getNewMessage = () => {
    this.setState({
      isMessageLoaded: false
    }, () => {
      this.waitOnMessage();
      this.randomMessage();
    })
  }

  randomMessage = () => {
    let message = this.state.messages[Math.floor(Math.random() * this.state.messages.length)];
    this.setState({
      message: message
    })
  }

  handleLoading = () => {
    return (
      <div className="loading">
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          <h3>A Message To You</h3>
          {this.state.isMessageLoaded === true ? <div className="message-container"><p>{this.state.message}</p></div> : this.handleLoading()}
        </div>
        {this.state.isMessageLoaded === true ? <button onClick={() => this.getNewMessage()}>I Want Another</button> : null}
      </div>
    );
  }
}