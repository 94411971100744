import React from 'react';
import './Photos.css';

// Images
import photo1 from './img/1.jpg';
import photo2 from './img/2.jpg';
import photo3 from './img/3.jpg';
import photo4 from './img/4.jpg';
import photo5 from './img/5.jpg';
import photo6 from './img/6.jpg';
import photo7 from './img/7.jpg';
import photo8 from './img/8.jpg';
import photo9 from './img/9.jpg';
import photo10 from './img/10.jpg';
import photo11 from './img/11.jpg';
import photo12 from './img/12.jpg';

export default class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: [
        photo1,
        photo2,
        photo3,
        photo4,
        photo5,
        photo6,
        photo7,
        photo8,
        photo9,
        photo10,
        photo11,
        photo12
      ]
    }
  }

  render() {
    return (
      <div className="page">
        <div className="container">
          <h3>Food Is Better With You</h3>
          <div className="image-container">
            {this.state.imgSrc.map((src) => (
              <img key={src} src={src} alt="dinner date" />
            ))}
          </div>
        </div>
      </div>
    );
  }
}