import React from 'react';
import './Music.css';

export default class Music extends React.Component {
  render() {
    return (
      <div className="page">
        <div className="container">
          <h3>Music's Better With You</h3>
          <div className="embed-container">
            <iframe src="https://open.spotify.com/embed/playlist/2bQNTXojxK5AH76LFg4hfd" allow="encrypted-media"></iframe>
          </div>
          <span className="text">This one's not on Spotify :(</span>
          <div className="embed-container">
            <iframe src="https://www.youtube.com/embed/aoYT0zATqJc" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    );
  }
}