import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Bar from './components/Bar/Bar';

import Music from './components/Music/Music';
import Photos from './components/Photos/Photos';
import Message from './components/Message/Message';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    }
  }

  updatePage = (pageNumber) => {
    this.setState({
      page: pageNumber
    })
  }

  render() {
    return (
      <div className="App">
        <Header />
        {this.state.page === 1 ? <Music /> : null}
        {this.state.page === 2 ? <Photos /> : null}
        {this.state.page === 3 ? <Message /> : null}
        <Bar page={this.state.page} updatePage={this.updatePage} />
      </div>
    );
  }
}