import React from 'react';
import './Header.css'

const Header = ({props}) => {
  
  function getDayName() {
    let date = new Date();
    let name = date.getDay();
    let weekday = [];
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    return weekday[name];
  }

  return (
    <header>
      <h3>Today is {getDayName()} &</h3>
      <h1>I Love You Abby ❤</h1>
    </header>
  );
}

export default Header;